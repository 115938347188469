<template>
  <v-row class="px-4">
    <v-col
      cols="12"
      xl="3"
      lg="4"
      md="6"
      sm="5"
      xs="12"
    >
      <v-card
        class="mx-auto"
        elevation="0"
      >
        <v-text-field
          placeholder="Filter elements"
          solo
          single-line
          dense
          flat
          hide-details
          append-icon="mdi-magnify"
          v-model="search"
          @input="selectedElement=undefined, filterElements()"
          class="my-2"
        />
        <v-divider />
        <v-list
          class="overflow-y-auto py-0 mx-2 element-list"
        >
          <v-list-item-group 
            v-model="selectedElement"
            color="accent"
          >
            <template v-for="(element, i) in filteredElements">
              <v-list-item :key="i" >
                <v-icon 
                  color="yellow darken-2"
                  left
                  v-if="!element.approved">
                  mdi-alert-circle
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <h5>{{ element.alias }}</h5>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <v-divider />
      </v-card>
    </v-col>
    <v-col>
      <!-- Elements -->
      <Element 
        v-if="selectedElement !== undefined"
        :element="filteredElements[selectedElement]"
        @updatedElement="updateElement($event)"
        @deleteElement="deleteElement($event)"
      /> 
      <v-card
        v-else
        flat
      >
        <v-row class="pa-3">
          <h1>
            {{ application.name }}
          </h1>
          <v-spacer />
          <v-btn
            depressed
            outlined
            color="accent"
            @click.stop="createDialog = true"
          >
            <v-icon 
              small 
              class="pr-3"
            >
              mdi-plus
            </v-icon>
            New Element
          </v-btn>
          <v-dialog
            v-model="createDialog"
            max-width="600px"
          >
            <EditElement 
              title="Create"
              :element="newElement"
              @setElement="createElement($event), createDialog = false"
              @cancelEdit="createDialog = false"
            />
          </v-dialog>
        </v-row>
        <v-card-text>
          <!-- <v-row>
            <v-col
              cols="12"
              md="12"
              lg="6"
              xl="4"
            >
              <v-card flat outlined>
                <v-card-title class="pb-0">
                  Add Errors
                </v-card-title>

                <v-list
                  dense
                >
                  <v-list-item
                    v-for="element in getApplicationErrors('add')"
                    :key="element.alias"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="element.alias" style="font-weight: lighter" />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="element.count" />
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-title>Total</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="my-0">
                      <v-list-item-action-text>{{ applicationErrors.addErrors.count }}</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <p v-else class="pa-4">
                  <v-icon color="green" class="pr-2">mdi-check</v-icon>
                  No Errors
                </p>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="12"
              lg="6"
              xl="4"
            >
              <v-ca
            </v-col>

            <v-col
              cols="12"
              md="12"
              lg="6"
              xl="4"
            >
              <v-card flat outlined>
                <v-card-title class="pb-0">
                  Other Errors
                </v-card-title>

                <v-list 
                  v-if="applicationErrors.otherErrors.count"
                  dense
                >
                  <v-list-item
                    v-for="element in applicationErrors.otherErrors.elements"
                    :key="element.alias"
                  >
                    <v-list-item-content class="py-0">
                      <v-list-item-title v-text="element.alias" style="font-weight: lighter" />
                    </v-list-item-content>
                    <v-list-item-action class="my-0">
                      <v-list-item-action-text v-text="element.count" />
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-list-item-title>Total</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="my-0">
                      <v-list-item-action-text>{{ applicationErrors.otherErrors.count }}</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <p v-else class="pa-4">
                  <v-icon color="green" class="pr-2">mdi-check</v-icon>
                  No Errors
                </p>
              </v-card>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import Element from "@/router/components/Element";
import EditElement from "@/router/components/EditElement";

const createElementMutation = `mutation (
    $element: CreateElementInput!
  ) {
  createElement(input: $element) {
    id
    application	
  }
}
`

const getElementByTypeQuery = `query ($applicationName: String, $nextToken: String, $edgeType: ElementType) {
  listElements(
    filter: {
      application: {eq: $applicationName}
    }, 
    nextToken: $nextToken
  ) {
    nextToken
    items {
      id
      alias
      persons(
        filter: {
          {type: {eq: $edgeType}}
        }
      ) {
        nextToken
        items {
          type
          createdAt
        }
      }
    }
  }
}`

const deleteElementMutation = `mutation ($elementID: ID!) {
  deleteElement(input: {id: $elementID}) {
    id
    name
  }
}`

export default {
  name: "Application",
  props: {
    "application": {
      type: Object,
      default () {
        return {
          elements: [],
          name: '',
        }
      }
    },
  },
  components: {
    Element,
    EditElement
  },
  data () {
    return {
      applicationElements: this.application.elements,
      filteredElements: this.application.elements.sort((a,b) => a.alias.localeCompare(b.alias)),
      applicationErrors: {
        'addErrors': {
          'elements': [],
          'count': 0
        },
        'removeErrors': {
          'elements': [],
          'count': 0
        },
        'otherErrors': {
          'elements': [],
          'count': 0
        }
      },
      search: '',
      selectedElement: undefined,
      createDialog: false,
      newElement: {
        name: '',
        alias: '',
        endpoint: '',
        approved: false,
        application: this.application.name,
        owner: this.$store.state.user.attributes.name.split('@')[0],
        removal_time: 0
      },
    }
  },

  watch: {
    application: {
      handler: function (newApplication) {
        if (this.applicationElements[0].application != newApplication.name) {
          this.selectedElement = undefined
          this.applicationElements = newApplication.elements
          this.search = ''
          this.filterElements()
          this.applicationErrors = []
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    async getApplicationEdge(edgeType) {
      let nextToken = 'nullToken';
      let elements = {
        'elements': [],
        'count': 0
      };

      while (nextToken !== null) {
        if (nextToken === 'nullToken') {
          nextToken = null;
        }

        await API.graphql(graphqlOperation(
          getElementByTypeQuery, 
          {
            'applicationName': this.application.name,
            'nextToken': nextToken,
            'edgeType': edgeType
          }
        ))
        .then(data => {
          nextToken = data.data.listElements.nextToken

          data.data.listElements.items.forEach(element => {
            if (element.persons.items.length > 0) {

              let elementCount = 0

              element.persons.items.forEach(connection => {
                if (connection.type === "ErrorAdd") {
                  elementCount++
                  elements.count++
                }
              })

              if (elementCount > 0) {
                elements.elements.push({
                  'alias': element.alias,
                  'count': elementCount
                })
              }
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
      }

      nextToken = 'nullToken'
      return elements
    },

    // Create Element
    async createElement(element) {
      await API.graphql(graphqlOperation(
        createElementMutation, { "element": element}
      ))
      .then(response => {
        this.createDialog = false
        element.id = response.data.createElement.id
        this.applicationElements.push(element)

        this.newElement = {
          name: '',
          alias: '',
          approved: false,
          application: this.application.name,
          owner: this.$store.state.user.attributes.name.split('@')[0],
          removal_time: 0,
          attributes: [],
          viewers: [],
          endpoint: '',
          provision_email: null,
          deprovision_email: null,
          schedule_email: null,
        }
        console.log("Element Created")
      })
      .catch(error => { console.log(error)})
    },

    updateElement(element) {
      this.$set(this.applicationElements, this.applicationElements.findIndex(i => i.alias === this.filteredElements[this.selectedElement].alias), element)
      this.filterElements()
      console.log("Element Updated")
    },

    filterElements () {
      this.filteredElements = this.applicationElements.filter((element)=>{
        return this.search.toLowerCase().split(' ').every(v => element.alias.toLowerCase().includes(v))
      }).sort((a,b) => a.alias.localeCompare(b.alias))
    },

    // Delete Element
    async deleteElement(elementID) {

      await API.graphql(graphqlOperation(
        deleteElementMutation, { 
          elementID: elementID
        }
      ))
      .then(() => {
        var applicationElementIds = this.applicationElements.map(element => element.id)

        this.applicationElements.splice(
          applicationElementIds.indexOf(elementID), 1
        )

        this.selectedElement = undefined
        this.filterElements()
        console.log("Element Deleted")
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style>
  h2 {
    font-weight: 300;
    font-size: 24px;
  }
  .element-list {
    height: 10vh;
    background-color: lightgrey;
  }

  .dashboard {
    align-content: center;
    justify-content: center;
  }

  @media only screen and (min-width: 600px) {
    .element-list {
      height: calc(100vh - 550px);
    }

    .dashboard {
      align-content: flex-end;
    }
  }
</style>