<template>
  <v-card 
    light
    class="pa-4"
  >
    <v-card-title class="pa-2">
      {{ titleText }} Element
    </v-card-title>
    <v-form>
      <v-row>
        <v-col 
          cols="8"
          class="pb-0"
        >
          <v-text-field 
            class="pl-4 py-3"
            label="Alias"
            :error-messages="aliasError"
            v-model="elementData.alias"
            hint="Display Name (e.g. Microsoft A3)"
          />
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            class="pr-4 py-3"
            label="Removal Time"
            suffix="days"
            v-model="elementData.removal_time"
            hint="Days between losing access and deprovisioning"
          />
        </v-col>
      </v-row>
      <v-text-field
        class="px-4 py-3"
        label="Consumer Name"
        :error-messages="nameError"
        v-model="elementData.name"
        hint="Name used to identify license (e.g. microsoft_a3)"
      />
      <v-text-field
        label="Provisioning Endpoint"
        :error-messages="endpointError"
        v-model="elementData.endpoint"
        class="px-4 pt-3"
      />
      <!-- Owner field only available if you are the owner of the element -->
      <v-text-field
        v-if="isAdmin || this.$store.state.user.attributes.name.split('@')[0] == this.element.owner"
        label="Owner"
        :error-messages="ownerError"
        v-model="elementData.owner"
        class="px-4 pt-3"
        hint="BYU NetID of the owner"
      />
      <!-- <v-row class="px-6">
        <v-switch
          v-model="byuApi"
          label="BYU API"
        />
        <v-text-field
          v-if="byuApi"
          label="BYU API Client ID"
          v-model="elementData.byu_api_client_id"
          class="px-4"
        />
      </v-row> -->
    </v-form>
    <v-card-actions class="pa-0">
      <v-spacer />
      <v-btn
        outlined
        @click="cancelUpdate"
      >
        Cancel
      </v-btn>
      <v-btn
        elevation="0"
        color="accent"
        @click="updateElement"
      >
        {{ titleText == 'Create' ? 'Create' : 'Save' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, integer, url, minValue, alphaNum } from 'vuelidate/lib/validators'

export default {
	props: [
    "title",
    "element"
  ],
  mixins: [ validationMixin ],
  validations: {
    elementData: {
      alias: { required },
      name: { required },
      application: { required },
      endpoint: { required, url },
      owner: { required, alphaNum },
      removal_time: { integer, minValue: minValue(0) }
    }
  },
	data () {
		return {
      titleText: this.title,
      elementData: this.element,
      viewers: this.element.viewers ? this.element.viewers.map(viewer => { return viewer }) : [],
      byuApi: this.element.byu_api_client_id ? true : false,
      isAdmin: this.$store.state.user.userGroups.includes('admin'),
      isOwner: this.$store.state.user.attributes.name.split('@')[0] == this.element.owner
		}
  },

  computed: {
    // Validtion Functions
    aliasError () {
      const errors = []
      if (!this.$v.elementData.alias.$dirty) return errors
      !this.$v.elementData.alias.required && errors.push('Alias is required')
      return errors
    },
    nameError () {
      const errors = []
      if (!this.$v.elementData.name.$dirty) return errors
      !this.$v.elementData.name.required && errors.push('Name is required')
      return errors
    },
    applicationError () {
      const errors = []
      if (!this.$v.elementData.application.$dirty) return errors
      !this.$v.elementData.application.required && errors.push('Application name is required')
      return errors
    },
    ownerError () {
      const errors = []
      if (!this.$v.elementData.owner.$dirty) return errors
      !this.$v.elementData.owner.required && errors.push('Owner NetId is required')
      !this.$v.elementData.owner.alphaNum && errors.push('Please enter a valid NetId. This should not be an email.')
      return errors
    },
    endpointError () {
      const errors = []
      if (!this.$v.elementData.endpoint.$dirty) return errors
      !this.$v.elementData.endpoint.required && errors.push('Endpoint is required')
      !this.$v.elementData.endpoint.url && errors.push('Endpoint must be a valid url')
      
      return errors
    },
    // elementData () {
    //   if (this.elementData.owner) {
    //     if ((this.elementData.owner).search("_") != -1){
    //       this.elementData.owner = this.elementData.owner.split("_")[1]
    //     }
    //     if ((this.elementData.owner).search("@") != -1){
    //       this.elementData.owner = this.elementData.owner.split("@")[0]
    //     }
    //   }
    //    return this.elementData
    // },
    previousElement () {
       return this.element
    }
  },
  watch: {
    element: function() {
      this.elementData = this.element
    },

  },

  methods: {
    updateElement() {
      // Validation
      this.$v.$touch()

      // Check if invalid
      if (!this.$v.$invalid) {
        // this.elementData.owner = ("Azure_" + this.elementData.owner + "@byu.edu")
        // console.log(this.elementData.owner) 
        this.$emit('setElement', this.elementData)
      }
    },

    // Assign element to element prior to update
    cancelUpdate () {
      this.elementData = this.previousElement
      this.$emit('cancelEdit', this.previousElement)
    }
  }
}
</script>
